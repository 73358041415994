.whats-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  cursor: pointer;
  z-index: 99999999;
}

.whats-link-customize {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  cursor: pointer;
  z-index: 99999999;
}

.logo-whats {
  width: 90px;
  height: 90px;
}

@keyframes pulseSunset {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
.pulseSunset {
  animation: pulseSunset 2s infinite alternate cubic-bezier(0.4, 0, 1, 1);
}

@media (max-width: 900px) {
  .logo-whats {
    width: 45px;
    height: 45px;
  }

  .whats-link-customize {
    top: 10px;
    right: 120px;
  }

  .pulseSunset {
    animation: none;
  }
}
